import BasePlugin from '../BasePlugin'

export default class ExportInvestmentExpensesBudgetOverallThirdFromScenario extends BasePlugin {
  async execute () {
    let me = this
    this.context.getCard().setLoading(true)
    let card = this.context.getCard()
    let idCorr = this.context.getModel().id

    if (this.context.getCard().data.hasOwnProperty('attr_3707_')) {
      idCorr = this.context.getModel().attr_3707_
    }

    card.setLoading(true)
    this.context.$http({
      method: 'post',
      url: `${this.context.$config.api}/etleditor/export/114`,
      responseType: 'arraybuffer',
      data: {
        'id_corr': idCorr
      }
    }).then(function (response2) {
      card.setLoading(false)
      me.openExportAttachment(response2.data, me, 'xlsx')
    }, (response) => { card.setLoading(false) })
  }
}
